import React, { useState } from 'react';
import SEO from '../../components/SEO';

import imageIllustrationMortageFirstSection from '../../assets/images/illustrations/RemortgagePage/variable-mortgage-first.png';
import imageIllustrationMortageFirstSectionMobile from '../../assets/images/illustrations/RemortgagePage/remortgage-first-section-mobile.png';
import imageIllustrationStepFirst from '../../assets/images/illustrations/RemortgagePage/step-image-first.png';
import imageIllustrationStepSecond from '../../assets/images/illustrations/RemortgagePage/step-image-second.png';
import imageIllustrationStepThird from '../../assets/images/illustrations/RemortgagePage/step-image-third.png';
import imageTestimonialsIndi1x from '../../assets/images/illustrations/indi-testimonials1x.png'
import imageTestimonialsIndi2x from '../../assets/images/illustrations/indi-testimonials2x.png'
import imageTestimonialsJenny1x from '../../assets/images/illustrations/jenny-testimonials1x.png'
import imageTestimonialsJenny2x from '../../assets/images/illustrations/jenny-testimonials2x.png'
import imageTestimonialsBrijesh1x from '../../assets/images/illustrations/brijesh-testimonials1x.png'
import imageTestimonialsBrijesh2x from '../../assets/images/illustrations/brijesh-testimonials2x.png'
import imageTestimonialsBella1x from '../../assets/images/illustrations/bella-testimonials1x.png'
import imageTestimonialsBella2x from '../../assets/images/illustrations/bella-testimonials2x.png'


import imageSecondSliderFirst from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderSecond from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderThird from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderFourth from "../../assets/images/illustrations/home-save-money-banner.png"

import imageSecondSliderFirstMobile from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderSecondMobile from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderThirdMobile from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderFourthMobile from "../../assets/images/illustrations/home-save-money-banner.png"



import CustomHeadingSection from '../../components/CommonSections/CustomHeadingSection';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import useWindow from '../../hooks/useWindow';
import StepsSection from '../../components/CommonSections/StepsSection';
import FAQSection from '../../components/FAQSection';
import HeaderLeftContent from '../../components/CommonSections/CustomHeadingSection/HeaderLeftContent';
import { Link } from 'gatsby';
import useSliderHeight from '../../hooks/useSliderHeight';
import CustomImage from '../../components/Image/Image';
import { useMemo } from 'react';
import loadable from '@loadable/component';

const ModalsFlow = loadable(() => import("../../components/Modals/ModalsFlow"))
const MortgageDetailsSection = loadable(() => import("../../components/CommonSections/MortgageDetails"))
const FullSizeSliderSectionMid = loadable(() => import("../../components/CommonSections/FullSizeSliderSectionMid"))
const SliderSection = loadable(() => import("../../components/CommonSections/SliderSection"))
const DownloadAppSection = loadable(() => import("../../components/CommonSections/DownloadAppSection"))
const ContactWidget = loadable(() => import("../../components/ContactWidget"))

const IS_MOBILE = () => (typeof window !== 'undefined' ? window.innerWidth <= 767.98 : 0);
const IS_TABLET = () => (typeof window !== 'undefined' ? window.innerWidth <= 991.98 : 0);





const FAQItems = [
  {
    title: 'Can I access the funds I’ve overpaid on my mortgage?',
    description: (
      <>
        In some cases, flexible mortgages allow you to access the funds you’ve overpaid. This can be done through a redraw facility, where you can withdraw the excess payments you’ve made. However, it is important to check with your lender about any specific requirements or restrictions on accessing these funds.
      </>
    ),
  },
  {
    title: 'How do overpayments work with a flexible mortgage?',
    description: (
      <>
      If you want the freedom to pay back your mortgage when you want, like with overpayments, then a flexible mortgage may be a good choice for you.
      <br />
      <br />
      Overpayments on a flexible mortgage allow you to pay more than your regular monthly repayment. Doing so means you can reduce the overall term of your mortgage and the amount of interest you'll pay over time.
      <br />
      <br />
      You can usually make unlimited overpayments on a flexible mortgage so can be a good option if you want to pay off your mortgage as quickly as possible by making lots of mortgage overpayments. Some flexible mortgages allow you to withdraw the amounts in the future, giving you access to those additional payments if needed.
      </>
    ),
  },
  {
    title: 'What are underpayments?',
    description: (
      <>
        Underpayments allow you to temporarily reduce your regular monthly repayments if you are already ahead of schedule on paying off your mortgage.
      </>
    ),
  },
  {
    title: 'What are payment holidays?',
    description: (
      <>
      A payment holiday is when the lender allows you to skip a monthly repayment or several monthly repayments (usually up to six).
      <br />
      <br />
      The conditions are different from lender to lender so it's worth checking what the conditions are for your deal. Most lenders will only allow this if you have previously overpaid. Since interest will still accrue throughout the payment holiday, your repayments might be a little higher when you resume them.
      </>
    ),
  },
  {
    title: 'Are there any penalties for making extra payments or paying off the mortgage early?',
    description: (
      <>
      Flexible mortgages are designed with your preferences in mind to be able to accommodate extra payments, fewer payments or breaks. However, it's crucial to review your mortgage agreement for any potential penalties or early repayment charges as it differs across lenders.
      Some lenders may impose fees for overpaying beyond a certain limit or paying off the mortgage before a specified period, known as the early repayment period.
      </>
    ),
  },
  {
    title: 'What is offsetting?',
    description: (
      <>
      With some flexible mortgages, you may also be able to take advantage of offsetting. This is where you can link your mortgage account to your current account, savings account, or both. By doing so, you can reduce the amount of mortgage interest you pay and potentially shorten the term of your mortgage.
      <br />
      <br />
      For example, if your mortgage balance is £200,000 and you have £20,000 in your linked current account, you will only pay interest on £180,000, rather than the full £200,000 of the mortgage balance. This in turn helps reduce the overall interest paid and potentially shorten the mortgage term.
      <br />
      <br />
      It may be a good idea to do offsetting if you've got cash in a savings account and it's not earning much interest, as it could be more beneficial to reduce the amount of interest you pay.
      </>
    ),
  },
  {
    title: 'How do I choose the best flexible mortgage for me?',
    description: (
     <>
     When choosing a flexible mortgage, you need to consider what features are most important to you and what you want out of your mortgage. Do you prioritise the security of knowing what your monthly payments will be, or do you want flexibility in how much you can pay each month?
     <br />
     <br />
     You should always compare mortgages to ensure that the one you decide on is right for your circumstance as there are many deals available. You need to factor in the term, any limits and conditions as well as the rate.
     There are important limits and conditions to check on a flexible mortgage. Such as:
     <li><strong>Minimum monthly repayments</strong> - this means you can't pay less than this each month or year
    </li>
    <li><strong>Maximum repayment limits</strong> - this is the most you can overpay each month or year</li>
    <li><strong>Interest charges</strong> - if you take a mortgage holiday, interest will still be charged, and your minimum repayment amount may increase after the break.</li>
    <li><strong>Mortgage holiday requests</strong> - this is usually a maximum of 6 months, sometimes only allowed if you've make overpayments in the past and changes from lender to lender so it's important to check.</li>
     </>
    ),
  },
  {
    title: 'Can I switch my standard mortgage to a flexible mortgage?',
    description: (
     <>
     This is dependent on your lender's terms. You may be able to exit your deal to switch to a flexible mortgage but this may come with associated penalties, so it's best to check.
     <br />
     <br />
     When your deal ends, you may be able to get a flexible deal if you remortgage.
     </>
    ),
  },
  {
    title: 'Will I incur fees if I over or underpay?',
    description: (
     <>
     You may incur a fee if you go over or under the minimum and maximum repayment limits set out in your mortgage terms. It's important to check your terms or speak with an advisor to avoid any unexpected fees.
     </>
    ),
  },
  {
    title: 'What are the benefits and drawbacks of an offset mortgage?',
    description: (
      <>
      As interest rates on savings are generally low, the savings you make on mortgage interest payments will, in general, be greater than the interest you could earn on your savings. You’ll also avoid paying the 20-25% tax on interest earned on savings since you’re paying no tax on the interest you save.
      <br />
      <br />
      They offer flexibility in how you use your savings. Unlike a mortgage overpayment, you’ll still have access to your savings. This can be particularly useful if you need spare cash available to pay for unexpected bills such as house repairs.
      <br />
      <br />
      On the flip side, you’ll also have the freedom to add more deposits to the savings account to reduce your mortgage interest even more. If you can get into the regular habit of making regular deposits into the savings account, you could save thousands of pounds on interest payments through the course of your mortgage.
      <br />
      <br />
      Some offset mortgages allow you to link savings accounts for more than one person. This can be handy for parents looking to help their children onto the property ladder. These are known as “Family Offset Mortgages”. By committing some savings to your child’s offset mortgage, you’ll help reduce their monthly payments while still retaining control of your savings. However, there are restrictions. The parents cannot get their money back until the mortgage has been paid to within 75-80% of the property value.
      <br />
      <br />
      Offset mortgages have their drawbacks and limitations, so it’s worth weighing up your options. The main drawback to an offset mortgage is the interest you’ll lose on your savings. If you’re somewhat dependent on savings interest to cover your bills, then you may want to think carefully about how your lifestyle may be affected by choosing an offset mortgage.
      <br />
      <br />
      Offset mortgages can have slightly higher rates than normal mortgages, on average 0.2%-0.5% more, which might undo some of the benefits of offsetting. For this reason it's worth checking that an offset mortgage will still save you money compared to a standard mortgage and savings account. Also be sure to check the terms of the offset mortgage as some lenders charge fees for overpayments on offset mortgages.
      <br />
      <br />
      Offset mortgages often offer a lower loan-to-value (LTV) ratio than conventional mortgages.
      </>
    ),
  },
  {
    title: 'Is an offset mortgage worth it?',
    description: (
      <>
      While there are clear benefits to an offset mortgage, this option may not be suitable or even possible for everyone.
      <br />
      <br />
      For starters, you’ll need a good chunk of savings to qualify and offset the mortgage. As a general rule of thumb, you should have savings that are 20-25% of your lump-sum mortgage.
      <br />
      <br />
      Offset mortgages are particularly attractive to higher-rate taxpayers. An offset mortgage allows you to avoid the 40-45% tax you would otherwise be paying on the interest earned on savings.
      <br />
      <br />
      There are other circumstances where an offset mortgage could make a lot of sense. If you’re self-employed, for example, an offset mortgage is well worth considering. Self-employed individuals need to set money aside to cover their tax bills. This money could be used in the interim to reduce mortgage payments.
      </>
    ),
  },
  {
    title: 'Should I remortgage early?',
    description: (
     <>
     If you're considering remortgaging early, it is advisable to seek advice and assistance from a mortgage broker. They are familiar with the offer periods of various lenders and can recommend deals that are likely to suit your individual circumstances. Their expertise can help you make an informed decision based on your specific needs.
     </>
    ),
  },
];

function FlexibleRatePage() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [dealsContactModal, setDealsContactModal] = useState(true)
  const { isDesktop, isMobile } = useWindow();
  const [isTablet, setTablet] = useState(false);
  const sliderHeight = useSliderHeight("611px", "198px", "234px")

  const secondSliderItems = useMemo(() => ([
    {
      image: (isMobile || isTablet) ? imageSecondSliderFirstMobile : imageSecondSliderFirst,
      title: "All you need to know about flexible mortgages?",
      firstDescription: "A flexible repayment mortgage is a mortgage deal that offers some flexibility to suit how you want to repay your mortgage.",
      secondDescription: (
        <>
         Although there are often certain limits such as minimum and maximum monthly or yearly payments; flexible mortgages give you more freedom in how you can make your monthly repayments compared to a fixed or tracker mortgage.<br /><br />
         You are able to make overpayments, and underpayments, and have payment holidays without resulting in any penalty charges or changing the terms and conditions of your mortgage.<br /><br />
         They allow you to adjust your payments to suit your financial situation and potentially reduce the interest you pay on your mortgage.
        </>
      )
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderSecondMobile : imageSecondSliderSecond,
      title: "What are the pros and cons of a flexible-mortgage?",
      secondDescription: (
        <>
          <b>Pros:</b><br /><br />
          You can make underpayments and overpayments or take a mortgage holiday at any point during the mortgage term. Payment holidays are only normally allowed if over payments have been made and you haven't exceeded your remitted amount.<br /><br />
          The main benefit of flexible mortgages is that they allow you to make additional payments on top of your regular ones. This will mean you can reduce your balance, as well as save on interest.<br /><br />
          They can be a good option if your income fluctuates, for example, if you're self-employed.<br /><br />
          The main benefit that differs from other types of mortgages is that it allows you to offset your savings against your mortgage balance. This means you can link any savings that you have in another account to reduce the amount of interest you pay, which can lead to significant savings.<br /><br />
          With a flexible mortgage, you have the ability to withdraw the funds you've previously offset against your mortgage, providing you with more liquidity and financial flexibility. This can be particularly useful in times of emergencies or when you need funds for other<br /><br />
          <b>Cons:</b> <br /><br />
          Flexible mortgages can be more complex to understand, so it's important you've done your research and compare mortgages or speak to a mortgage broker to ensure that this is the right option for you.<br /><br />
          You may still impose restrictions such as early repayment charges (ERC) for very large overpayments.<br /><br />
          You may have higher interest rates and fees on a flexible mortgage deal compared to a fixed or variable mortgage with no flexible features. The added features and flexibility offered by these mortgages can result in higher borrowing costs.<br /><br />
          Your savings won't earn any interest rates if they are used for an offset mortgage.<br /><br />
          Flexible mortgages are not as common or as widely available as traditional mortgage products. Some lenders may not offer these types of mortgages at all, so you'll have limited options and you may need to seek a specialised lender specifically for a flexible mortgage.<br /><br />
        </>
      )
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderThirdMobile : imageSecondSliderThird,
      title:"Who are flexible mortgages suitable for?",
      firstDescription: "Flexible mortgages are beneficial for people who have big savings that they won't need to access for a long time.",
      secondDescription: (<>
        Those who have unpredictable incoming and outgoings with fluctuating income, irregular cash flows, or savings they want to offset against their mortgage will benefit from a flexible repayment mortgage.<br /><br />
        Generally, they work best if your overpayments outweigh both your underpayments and any payment holidays. If you’re likely to keep taking money back the money you overpay, then a traditional mortgage would be a more cost-effective option.<br /><br />
        Also, people who want to access their cash for things like renovations or to help get children on the property ladder may find this beneficial.<br /><br />
        They are best suited to self-employed individuals or small business owners or those expecting their financial circumstances to change over the term of the mortgage.
      </>),
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderFourthMobile : imageSecondSliderFourth,
      title: "What are the types of flexible mortgages in the UK?",
      firstDescription: "There are a few different types of flexible mortgages to choose from.",
      secondDescription: (
        <>
          <strong>Flexible Offset Mortgage</strong><br /><br />
          This is where you can link your mortgage account to your current account, savings account, or both to reduce the amount of mortgage interest you pay and potentially shorten the term of your mortgage.<br /><br />
          <strong>Flexible Repayment Mortgages</strong><br /><br />
          A flexible repayment mortgage, also known as a capital repayment mortgage, allows you to make monthly repayments towards both the interest and capital amount throughout the term of your mortgage.<br /><br />
          <strong>Flexible fixed-rate mortgage</strong><br /><br />
          Like a standard fixed-rate mortgage, your interest rate and mortgage repayments will stay the same on a flexible fixed-rate mortgage for the duration of the mortgage term (usually 2, 3 or 5 years).<br /><br />
          Fixed rates are generally more expensive because of the certainty and security you get.<br /><br />
          When the mortgage comes to an end, you will need to remortgage or you will be moved to your lender's SVR which is usually more expensive.<br /><br />
          What makes the flexible mortgage different is that as an addition you can adjust your monthly repayments depending on your circumstances.<br /><br />
          <strong>Flexible tracker mortgage</strong><br /><br />
          A flexible tracker mortgage is a mortgage deal that offers the flexibility of making more overpayments, underpayments or payment holidays but on the set-up of a tracker mortgage. This means that the interest rate follows the market and the Bank of England base rate.<br /><br />
          A flexible tracker mortgage means that your monthly repayments are likely to change, so this type of mortgage would be suitable for people who are able to afford it if their mortgage increases.
        </>
      )
    },
  ]), [isMobile, isTablet]);


  return (
    <>
      <SEO title="Remortgage Page" />
      <div className="common-page remortgage-page">
        <div className="remortgage-page__heading-container">
          <SectionWrapper isFirstSection={true}>
            <CustomHeadingSection
              leftContent={
                <HeaderLeftContent
                  title={
                    <>
                      What is a flexible
                      {isDesktop ? <br /> : ' '}
                      mortgage?
                    </>
                  }
                  image={isMobile ? imageIllustrationMortageFirstSectionMobile : null}
                  imageReversed
                  description="In just a few clicks we can help you find personalised flexible mortgage deals across the market."
                  buttonText="Compare Deals"
                  hashUrlOnClick="/mortgages/remortgage#mortgage-details"
                  useWidget
                />
              }
            />
            {!isMobile && (
              <div className="main-image-container">
                <CustomImage
                    className="w-100 main-image"
                    src={imageIllustrationMortageFirstSection}
                    alt="main image"
                  />
              </div>
            )}
          </SectionWrapper>
        </div>
        <div style={{minWidth: "100vw", minHeight: isMobile ? "826px" : "1000px"}}>
          <FullSizeSliderSectionMid
            sliderItems={secondSliderItems}
          />
        </div>
        <FAQSection
          FAQItems={FAQItems}
          title={
            <>
              Have a question about flexible-mortgages?
              {isDesktop ? <br /> : ' '}
              Check out our FAQ’s
            </>
          }
        />
        <div style={{minHeight: isMobile ? "382px" : "429px"}}>
          <DownloadAppSection />
        </div>
        <ContactWidget
          handleClick={() => {
            setIsContactModalOpen(true)
            setDealsContactModal(false)
          }}
          title="Speak to a mortgage expert for FREE"
        />
        <ModalsFlow
          isFirstOpened={isContactModalOpen}
          setIsFirstOpened={(state) => setIsContactModalOpen(state)}
          dealsContactModal={dealsContactModal}
        />
      </div>
    </>
  );
}



export default FlexibleRatePage;
